.sfconnect-header {
  margin-bottom: 10px;
  background-color: $primary-merna-03-digital;

  > .-oneX-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;

    .header-row {
      width: 100%;

      // img {
      //   @supports ((display: contents) and (caret-color: auto)) {
      //     background-size: cover;  /* or initial */
      //     background-position: initial;
      //     width: 100px;
      //     height: auto;
      //   }
      // }
    }

    /*overide 1x icon color */
    .-oneX-icon .icon {
      fill: $primary-white;
    }

    .account-info {
      position: relative;
      // top: 10px;
      @media (max-width: 766px) {
        top: 3px;
      }
      .heading {
        font-size: 16px;
        color: $primary-white;
      }
      .moaDiv {
        color: $primary-white;
        .moaLink {
          color: $primary-white;
        }
      }
      .info-container {
        margin-left: 0;
        margin-right: 0;
        .name {
          font-size: 16px;
          color: $primary-white;
        }
      }
    }

    .gear {
      &-icon {
        height: 36px;
        width: 36px;
        position: absolute;
        display: inline-block;
        .-oneX-icon {
            margin: 0 auto;
            position: relative;
            left: 4px;
        }
        .gear-image-for-header{
          height: 32px;
          width: 32px;
        }
      }
      &-text {
        color: $primary-white;
        position: relative;
        left: 45px;
        color: $primary-white;
        background-color: $primary-red;
        width: "max-content";
        @media (max-width: 766px) {
          top: 3px;
        }

        .heading {
          font-size: 16px;
          color: $primary-white;
        }
        .info-container {
          margin-left: 0;
          margin-right: 0;
          .phone {
            font-size: 16px;
            color: $primary-white;
          }
        }
      }
    }
  }

  .changeOfficeButton {
    background: none;
    border: none;
    padding: 0;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }

  .-header-padding {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1px;
  }
}
