.sfconnect-chat-card {
  &:focus,
  &:hover,
  &:focus-within {
    /* This is needed to be able to tab to the inner button for accessiblity */
    .-sfc-onhover-container {
      //display: inline;
    }
  }
  .-sfc-onhover-container {
    //padding-top: 15px;
    //width: inherit;
    //z-index: 10;
    display: block;
    //position: relative;
    top: 0;
    //left: 50%;
    margin: 0 auto;
    //padding-right: 0;
    cursor: pointer;
    .-sfc-hide-button {
      //position: right;
      //padding-left: 65%;
      //padding-right: 2px;
      button.-oneX-btn-small {
        width: 90px;
        height: 26px;
      }
      //padding-bottom: 2px;
    }
    .-sfc-hide-button-left {
      //position: right;
      padding-left: 10%;
      padding-top: 8px;
      padding-right: 3px;
      button.-oneX-btn-small {
        width: 76px;
        height: 26px;
      }
      //padding-bottom: 2px;
    }
    .-sfc-cancel-button {
      position: right;
      padding-left: 78%;
      //padding-top: 8px;
      padding-right: 2px;
      button.-oneX-btn-small {
        width: 90px;
        height: 26px;
      }
      //padding-bottom: 2px;
    }
    .-sfc-archive-button {
      position: right;
      padding-top: 8px;
      padding-left: 15%;
      padding-right: 2px;
      button.-oneX-btn-small {
        width: 76px;
        height: 26px;
      }
    }
    .-sfc-assign-button {
      position: left;
      width: fit-content;
      height: 26px;
      border: solid 1px;
      border-radius: 30px;
      text-transform: none;

      .enabled {
        background-color: #fff;
        border-color: #d62311;
        color: #d62311;
      }
    }

    .-sfc-assign-button:enabled {
      background-color: #fff;
      border-color: #d62311;
      color: #d62311;
    }

    .-sfc-assignment-button {
      padding-top: 8px;
      padding-left: 0px;
    }
    .-sfc-assignment-button-left {
      padding-left: 0px;
    }
  }
  @media screen and (min-device-width: 100px) and (max-device-width: 812px) {
    .sfconnect-chat-card {
      .-sfc-onhover-container {
        margin: 40px;
      }
    }
  }
}
