.sfconnect-message {
  display: flex;
  background: $primary-white;
  .message-number,
  .message-date,
  .message-time {
    padding: 0;
  }
  .message-date,
  .message-time {
    text-align: right;
    padding-right: 10px;
  }
  .message-time {
    position: relative;
    top: -5px;
  }
  .message {
    width: 85%;
  }
  &.customer {
    .info-area {
      display: flex;
      justify-content: flex-start;
    }
    .message-container {
      padding-left: 30px;
    }
  }
  &.agent {
    justify-content: flex-end;
    background: $primary-white;
    .info-area {
      display: flex;
      justify-content: flex-end;
    }
    .message-info.info-area {
      color: nth($accent-red, 2);
    }
    .initial-section {
      background-color: nth($accent-red, 2);
    }
    .message-container {
      //color: #505050;
      color: $primary-charcoal-02;
      text-align: left;
      .message-body {
        align-self: flex-end;
      }
    }
  }
  .message-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: nth($accent-red, 1);
    //font-size: 14px;
    .display-attachment {
      display: flex;
      flex-direction: column;
    }
  }
}

.sfconnect-message.agent {
  justify-content: flex-end;
  background: $primary-white;
  //border-bottom: 1px solid #e6e6e6;
  border-bottom: 1px solid $primary-gray;
}

.sfconnect-chat-card {
  padding: 15px;
  border: 1px solid nth($primary-gray, 7);
  width: inherit;
  .chat-preview {
    display: flex;
    padding-left: 35px;
    padding-right: 0;
  }
  .initial-section {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background: $primary-black;
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
    color: $primary-white;
    position: relative;
    span {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info-area {
    // display: flex;
    // justify-content: space-between;
    margin-left: 0;
    margin-right: 0;

    .empty-section {
      height: 40px;
      width: 40px;
      border-radius: 100%;

      margin-right: 10px;
      padding-top: 10px;
      font-size: 18px;
      font-weight: bold;
      color: $primary-white;
      background-color: $primary-black;
      border-color: nth($primary-gray, 3);
      border: 1px solid $primary-white;
      text-align: center;
    }

    .message-star {
      margin-right: auto;
      padding-bottom: 50px;
      .name {
        font-size: 14px;
      }
      
    }

    .name-number {
      margin-right: auto;
      .name,
      h3.number {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .number {
        font-size: 12px;
      }
    }
    .date-container {
      .date {
        color: nth($primary-gray, 3);
      }
    }
  }
}

.sfconnect-message {
  padding: 15px;
  border: 1px solid nth($primary-gray, 7);
  height: auto;
  width: inherit;
  .chat-preview {
    display: flex;
    padding-left: 35px;
    padding-right: 0;
  }
  .initial-section {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background: $primary-black;
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
    color: $primary-white;
    position: relative;
    span {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info-area {
    margin-left: 0;
    margin-right: 0;

    .name-number {
      margin-right: auto;
      .name,
      h3.number {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .number {
        font-size: 12px;
      }
    }
    .date-container {
      .date {
        color: nth($primary-gray, 3);
      }
    }
  }
}

.sfconnect-chat-card {
  &.unread,
  &.unread:hover {
    border-left: solid $primary-error 10px;
    .-oneX-row.info-area,
    .chat-preview {
      margin-left: -10px;
    }
  }
  &.no-reply,
  &.no-reply:hover {
    border-left: solid $primary-denim 10px;
    .-oneX-row.info-area,
    .chat-preview {
      margin-left: -10px;
    }
  }
  &.active {
    background: $primary-white;
    border: 2px solid $primary-black;
    border-left: solid $primary-gray-02 10px;
    &:hover {
      border-left: solid $primary-gray-02 10px;
      &:focus {
        border-left: solid $primary-gray-02 10px;
      }
    }
  }

  &.active.unread,
  &.active.no-reply {
    border-left: solid $primary-error 10px;
    border-right: 1px solid $primary-black;
    border-top: 1px solid $primary-black;
    border-bottom: 1px solid $primary-black;
  }

  &:hover {
    background: $primary-white;
    border: 2px solid $primary-black;

    .date-container {
      .date {
        color: $primary-black;
      }
    }
    .empty-section {
      color: $primary-white;
      background: #155724;
      text-align: center;
    }
  }

  .search-clear-button {
    right: 64px;
    top: 0px;
    display: inline-block;
    border-right: none;
    border-radius: 0;
  }
  .search-submit-button {
    right: 64px;
    top: 0px;
    display: inline-block;
    border-right: none;
    border-radius: 0;
  }

  .star-col {
    display: flex;
    align-items: center;
    padding: 0;
    .star-container.-oneX-icon-container.-oneX-icon--interactive {
      width: fit-content;
      float: right;
      position: relative;
      top: -2px;
      margin-left: auto;
      margin-top: 0px;
      padding: 0;
      .-oneX-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        .icon {
          fill: #403c3c;
        }
        &.starred .icon {
          fill: #f1aa1b;
        }
      }
      .star-button {
        background-color: transparent;
        border: 0px;
        width: 60px;
        padding-bottom: 10px;
        &:hover {
          cursor: pointer;
        }
      }
      .starred-label {
        color: #f1aa1b;
        font-size: 12px;
      }
    }
  }

  .-sfc-following,
  .-sfc-follow {
    font-family: "Times New Roman", Times, serif;
    font-size: 26px;
    font-weight: 580;
  }
  .-sfc-following {
    color: #f1aa1b;
  }
}
